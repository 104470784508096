* {
  margin: 0;
  padding: 0;
}

@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);
html,body {
    font-family: nanumgothic !important;
}

div{
  padding: 0;
}

/* BlockStyle */
.white-block {
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
}
.gray-block {
  background: #efefef;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #777;
}
.graffiti-block {
  background: #f3b5a8;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #000000;
}

/* Navigation */
#nav-bar {
  top: 0;
  z-index: 10;
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-css{
  border-bottom: 1px solide grid;
}
.navbar-brand img{
  height: 40px;
  padding-left: 30px;
}
.navbar-nav li{
  padding: 0 10px;
}
.navbar-nav li a{
  float: right;
  text-align: left;
}
#nav-bar ul li a:hover {
  color: #007bff!important;
}
.navbar{
  background: #fff;
}
.navbar-toggler{
  border: none!important;
}
.nav-link{
  color: #555!important;
  font-weight: 600;
  font-size: 16px;
}
/* mainimg */
#main-img{
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(./assets/img/graffiti_main.png);
  background-size: cover;
  background-position: center;
  color: #fff;
  background-attachment: fixed;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 55px;
}
#main-img img{
  width: 100%;
}
.main-img-top{
  margin-bottom: 0px;
}
.main-img-bottom{
  margin-bottom: 60px;
}
#main-img button{
  height: 60px;
  width: 150px;
  border: none;
  font-size: 20px;
}

/* About  */

#about{
  padding-top: 50px;
  padding-bottom: 50px;
  color: #555;
}
#about .btn{
  margin-top: 20px;
  margin-bottom: 30px;
}
#about img{
  width: 80%;
}
.about-content{
  padding-top: 20px;
}
.skills-bar p{
  margin-bottom: 6px;
  font-weight: 600;
}
.progress-bar{
  border-radius: 16px;
}
.progress{
  border-radius: 16px !important;
  margin-bottom: 20px;
}

/* Services */

#program{
  background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8));
  background-size: cover;
  background-position: center;
  color: #efefef !important;
  background-attachment: fixed;
  padding-top: 50px;
  padding-bottom: 50px;
}
#program h1{
  text-align: center;
  color: #efefef !important;
  padding-bottom: 10px;
}
#program h1::after{
  content: '';
  background: #efefef;
  display: block;
  height: 3px;
  width: 170px;
  margin: 20px auto 5px;
}
#program img{
  width: 80%;
}
.program{
  margin-top: 40px;
}
.time {
  margin-top: 40px;
}
#program p{
  font-size: 14px;
  margin-top: 20px;
  color: #ccc;
}
.program .col-md-6:hover{
  background: #007bff;
  cursor: pointer;
  transition: 0.7s;
}

/* Team Member */

#team{
  padding-top: 50px;
  padding-bottom: 50px;
  color: #555;
}
h1{
  text-align: center;
  color: #555 !important;
  padding-bottom: 10px;
}
h1:after{
  content: '';
  background: #BC4D34;
  display: block;
  height: 3px;
  width: 170px;
  margin: 30px auto 5px;
  margin-bottom: 50px;
}
.profile-pic{
  margin-top: 25px;
}
.profile-pic .img-box{
  opacity: 1;
  display: block;
  position: relative;
}
.profile-pic .img-box img{
  filter: grayscale(1);
  width: 100%;
}
.profile-pic .img-box img:hover{
  filter: grayscale(0);
  cursor: pointer;
}
.profile-pic h2{
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  color: #007bff !important;
}
.profile-pic h3{
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;
}
#team .fab{
  height: 25px;
  width: 25px;
  color: #007bff !important;
  background: #fff;
  padding: 4px;
  border-radius: 50%;
}
.img-box ul{
  padding: 15px 0;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
.img-box ul li{
  padding: 5px;
  display: inline-block;
}
.img-box:hover ul{
  opacity: 1;
}
.img-box ul, .img-box ul li{
  transition: 0.5s;
}

/* Promo */

#promo{
  background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)) ;
  background-size: cover;
  background-position: center;
  color: #fff;
  background-attachment: fixed;
  text-align: center;
  padding: 100px;
  font-family: sans-serif;
  font-size: 38px;
}

/* Price Plans */

#price{
  padding: 40px 0;
  background: #efefef;
}
.single-price{
  margin: 10px auto;
  display: inline;
  float: left;
  width: 100%;
  background-color: #fff;
  transition: 0.5s;
}
.single-price:hover{
  box-shadow: 0 2px 20px #333;
}
.price-head{
  background-color: #2196f3;
  display: inline;
  float: left;
  padding: 10px 5px;
  text-align: center;
  width: 100%;
}
.price-head h2{
  color: #fff;
  font-size: 30px;
  font-weight: 5px;
  padding: 5px;
  text-transform: uppercase;
}
.price-head p{
  font-size: 25px;
  color: #ecf0f1;
  font-weight: bold;
  line-height: 30px;
}
#price span{
  font-size: 15px;
}
.price-content{
  display: inline;
  float: left;
  width: 100%;
  padding: 0 15px;
}
.price-content ul li{
  border-bottom: 1px solid #efefef;
  padding: 15px 10px;
  list-style: none;
}
ul li:last-child{
  border: none;
}
.fa-check-circle{
  color: green;
  margin-right: 10px;
  font-size: 20px;
}
.fa-times-circle{
  color: red;
  margin-right: 10px;
  font-size: 20px;
}
.price-button{
  display: inline;
  float: left;
  padding: 15px 15px 30px;
  text-align: center;
  width: 100%;
}
.buy-btn{
  background-color: #2196f3;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  padding: 15px 40px;
  transition: 0.5s;
}
.buy-btn:hover{
  border-color: 1px solid #fff;
  background-color: #3f51b5;
}
a{
  text-decoration: none!important;
  color: #fff!important;
}

/* Footer */
#footer{
  background: #333;
  color: #fff;
  padding: 12px;
}

.fa-heart{
  margin: 3px;
  color: red;
}

/* Contact */
.contact-form{
  padding: 15px;
}
.form-control{
  border-radius: 0 !important;
  border: none !important;
  color: #777;
}
::placeholder{
  color: #999 !important;
}