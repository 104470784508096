@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);
* {
  margin: 0;
  padding: 0;
}
html,body {
    font-family: nanumgothic !important;
}

div{
  padding: 0;
}

/* BlockStyle */
.white-block {
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
}
.gray-block {
  background: #efefef;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #777;
}
.graffiti-block {
  background: #f3b5a8;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #000000;
}

/* Navigation */
#nav-bar {
  top: 0;
  z-index: 10;
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-css{
  border-bottom: 1px solide grid;
}
.navbar-brand img{
  height: 40px;
  padding-left: 30px;
}
.navbar-nav li{
  padding: 0 10px;
}
.navbar-nav li a{
  float: right;
  text-align: left;
}
#nav-bar ul li a:hover {
  color: #007bff!important;
}
.navbar{
  background: #fff;
}
.navbar-toggler{
  border: none!important;
}
.nav-link{
  color: #555!important;
  font-weight: 600;
  font-size: 16px;
}
/* mainimg */
#main-img{
  width: 100%;
  background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,0.4)),to(rgba(0,0,0,0.4))), url(/static/media/graffiti_main.d71ea1ba.png);
  background-image: -webkit-linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(/static/media/graffiti_main.d71ea1ba.png);
  background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(/static/media/graffiti_main.d71ea1ba.png);
  background-size: cover;
  background-position: center;
  color: #fff;
  background-attachment: fixed;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 55px;
}
#main-img img{
  width: 100%;
}
.main-img-top{
  margin-bottom: 0px;
}
.main-img-bottom{
  margin-bottom: 60px;
}
#main-img button{
  height: 60px;
  width: 150px;
  border: none;
  font-size: 20px;
}

/* About  */

#about{
  padding-top: 50px;
  padding-bottom: 50px;
  color: #555;
}
#about .btn{
  margin-top: 20px;
  margin-bottom: 30px;
}
#about img{
  width: 80%;
}
.about-content{
  padding-top: 20px;
}
.skills-bar p{
  margin-bottom: 6px;
  font-weight: 600;
}
.progress-bar{
  border-radius: 16px;
}
.progress{
  border-radius: 16px !important;
  margin-bottom: 20px;
}

/* Services */

#program{
  background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,0.8)),to(rgba(0,0,0,0.8)));
  background-image: -webkit-linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8));
  background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8));
  background-size: cover;
  background-position: center;
  color: #efefef !important;
  background-attachment: fixed;
  padding-top: 50px;
  padding-bottom: 50px;
}
#program h1{
  text-align: center;
  color: #efefef !important;
  padding-bottom: 10px;
}
#program h1::after{
  content: '';
  background: #efefef;
  display: block;
  height: 3px;
  width: 170px;
  margin: 20px auto 5px;
}
#program img{
  width: 80%;
}
.program{
  margin-top: 40px;
}
.time {
  margin-top: 40px;
}
#program p{
  font-size: 14px;
  margin-top: 20px;
  color: #ccc;
}
.program .col-md-6:hover{
  background: #007bff;
  cursor: pointer;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

/* Team Member */

#team{
  padding-top: 50px;
  padding-bottom: 50px;
  color: #555;
}
h1{
  text-align: center;
  color: #555 !important;
  padding-bottom: 10px;
}
h1:after{
  content: '';
  background: #BC4D34;
  display: block;
  height: 3px;
  width: 170px;
  margin: 30px auto 5px;
  margin-bottom: 50px;
}
.profile-pic{
  margin-top: 25px;
}
.profile-pic .img-box{
  opacity: 1;
  display: block;
  position: relative;
}
.profile-pic .img-box img{
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  width: 100%;
}
.profile-pic .img-box img:hover{
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  cursor: pointer;
}
.profile-pic h2{
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  color: #007bff !important;
}
.profile-pic h3{
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;
}
#team .fab{
  height: 25px;
  width: 25px;
  color: #007bff !important;
  background: #fff;
  padding: 4px;
  border-radius: 50%;
}
.img-box ul{
  padding: 15px 0;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
}
.img-box ul li{
  padding: 5px;
  display: inline-block;
}
.img-box:hover ul{
  opacity: 1;
}
.img-box ul, .img-box ul li{
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* Promo */

#promo{
  background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,0.7)),to(rgba(0,0,0,0.7))) ;
  background-image: -webkit-linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)) ;
  background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)) ;
  background-size: cover;
  background-position: center;
  color: #fff;
  background-attachment: fixed;
  text-align: center;
  padding: 100px;
  font-family: sans-serif;
  font-size: 38px;
}

/* Price Plans */

#price{
  padding: 40px 0;
  background: #efefef;
}
.single-price{
  margin: 10px auto;
  display: inline;
  float: left;
  width: 100%;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-price:hover{
  box-shadow: 0 2px 20px #333;
}
.price-head{
  background-color: #2196f3;
  display: inline;
  float: left;
  padding: 10px 5px;
  text-align: center;
  width: 100%;
}
.price-head h2{
  color: #fff;
  font-size: 30px;
  font-weight: 5px;
  padding: 5px;
  text-transform: uppercase;
}
.price-head p{
  font-size: 25px;
  color: #ecf0f1;
  font-weight: bold;
  line-height: 30px;
}
#price span{
  font-size: 15px;
}
.price-content{
  display: inline;
  float: left;
  width: 100%;
  padding: 0 15px;
}
.price-content ul li{
  border-bottom: 1px solid #efefef;
  padding: 15px 10px;
  list-style: none;
}
ul li:last-child{
  border: none;
}
.fa-check-circle{
  color: green;
  margin-right: 10px;
  font-size: 20px;
}
.fa-times-circle{
  color: red;
  margin-right: 10px;
  font-size: 20px;
}
.price-button{
  display: inline;
  float: left;
  padding: 15px 15px 30px;
  text-align: center;
  width: 100%;
}
.buy-btn{
  background-color: #2196f3;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  padding: 15px 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.buy-btn:hover{
  border-color: 1px solid #fff;
  background-color: #3f51b5;
}
a{
  text-decoration: none!important;
  color: #fff!important;
}

/* Footer */
#footer{
  background: #333;
  color: #fff;
  padding: 12px;
}

.fa-heart{
  margin: 3px;
  color: red;
}

/* Contact */
.contact-form{
  padding: 15px;
}
.form-control{
  border-radius: 0 !important;
  border: none !important;
  color: #777;
}
::-webkit-input-placeholder{
  color: #999 !important;
}
::-moz-placeholder{
  color: #999 !important;
}
:-ms-input-placeholder{
  color: #999 !important;
}
::-ms-input-placeholder{
  color: #999 !important;
}
::placeholder{
  color: #999 !important;
}
.MainImg img{
  width: 100%;
}
.About{
  text-align: center;
}
.About_reason_1{
  margin-top: 30px;
}
.About_reason_2{
  margin-top: 50px;
}
.About_reason_3{
  margin-top: 50px;
}
.faq-ans{
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  background: #f6f6f6;
  margin-bottom: 0;
}
.accordion {
  border-radius: 0;
  border: none;
}
.accordion .accordion-item:first-child .title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.accordion .accordion-item:last-child .title {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accordion .accordion-item .title {
  background-color: #ffffff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.accordion .accordion-item .title:after {
  content: '\2795';
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}
.accordion .accordion-item .panel {
  max-height: 0;
  -webkit-transition: max-height 0.15s ease-out;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.accordion .accordion-item.active .title {
  font-weight: bold;
  background: white;
}
.accordion .accordion-item.active .title:after {
  content: '\2796';
}
.accordion .accordion-item.active .panel {
  max-height: 500px;
  -webkit-transition: max-height 0.25s ease-in;
  transition: max-height 0.25s ease-in;
}
/*# sourceMappingURL=index.css.map*/

.Program{
  /* text-align: center; */
}
.Program h2{
  color: #000000;
  padding-left: 30px;
}
.Program p{
  font-size: 20px;
  padding-left: 30px;
  padding-right: 20px;
  text-align: justify;
}
.Program h2::after{
  content: '';
  background: #000000;
  display: block;
  height: 3px;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.Program .icon{
  padding-left: 30px;
  width: 100px;
  margin-bottom: 30px;
}
.Timetable{
  text-align: center;
}
.Timetable_img{
  margin-top: 20px;
}
.Sponsor{
  text-align: center;
}

.Sponsor img {
  width: 100%;
}

.Sponsor_tobe{
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 60px;
  color: #2b2b2b;
}

.sponsorship-board {
  padding-left: 10rem;
  padding-right: 10rem;
}

.sponsorship-logo {
  margin: 2rem;
  max-height: 5rem;
}
#speakers-image {
  margin: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
