.Sponsor{
  text-align: center;
}

.Sponsor img {
  width: 100%;
}

.Sponsor_tobe{
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 60px;
  color: #2b2b2b;
}

.sponsorship-board {
  padding-left: 10rem;
  padding-right: 10rem;
}

.sponsorship-logo {
  margin: 2rem;
  max-height: 5rem;
}